import { useRef, useEffect } from "react";
import plusIcon from "../../../assets/icons/plusIcon.png";

function AccordionItem({ item, onClick, isOpen }) {
  const itemRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      const height = itemRef.current.scrollHeight;
      itemRef.current.style.height = `${height}px`;
    } else {
      itemRef.current.style.height = "0px";
    }
  }, [isOpen]);

  return (
    <li className="accordion-item">
      <button onClick={onClick} className="accordion-header mont-b">
        {item.title}
      </button>
      <div className="accordion-collapse" ref={itemRef}>
        <div className="accordion-body-header">
          <div className="accordion-body-header-wrapper">
            <p className="accordion-header-service mont-b">Послуга</p>
            <p className="accordion-header-price mont-b">Ціна</p>
            <p className="accordion-header-garanty mont-b">Гарантія</p>
            <p className="accordion-header-terms mont-b">Термін</p>
          </div>
        </div>
        {item.services.map((service, idx) => {
          return (
            <div key={idx} className="accordion-body">
              <div className="accordion-body-wrapper">
                <p className="accordion-service int-m">{service.service}</p>
                <p className="accordion-price int-m">{service.price}</p>
                <p className="accordion-price-mobile int-m">
                  Ціна: {service.price}
                </p>
                <p className="accordion-garanty int-m">{service.garanty}</p>
                <p className="accordion-garanty-mobile int-m">
                  Гарантія: {service.garanty}
                </p>
                <p className="accordion-terms int-m">{service.terms}</p>
                <p className="accordion-terms-mobile int-m">
                  Термін: {service.terms}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <img
        onClick={onClick}
        src={plusIcon}
        alt="зображення плюсика. при натисканні відкриється компонент відповіді"
        className={`plus__icon ${isOpen ? "active" : ""}`}
      />
    </li>
  );
}

export default AccordionItem;
