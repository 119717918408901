export const stepsArr = [
    {
        number: 1,
        title: "Залишіть заявку",
        description: "Ви можете залишити заявку онлайн на сайті або зателефонувати нам по телефону, чи написати в соціальні мережі.",
    },
    {
        number: 2,
        title: "Консультація та розрахунок",
        description: "Менеджер розпитає вас про проблему. Ми робимо попередній розрахунок вартості ремонту та погоджуємо час приїзду майстра.",
    },
    {
        number: 3,
        title: "Виїзд майстра протягом години",
        description: "Фахівець виїжджає у призначений час та місце та проводить діагностику вашого пристрою. Діагностика надається безкоштовно за умови ремонту у нас.",
    },
    {
        number: 4,
        title: "Техніка як нова",
        description: "Майстер здійснює ремонт вашої техніки, якщо це можливо, чи, якщо це необхідно, забирає ваш пристрій до сервісного центру.",
    },
    {
        number: 5,
        title: "Видача гарантійного чека",
        description: "Фахівець підписує документи та виписує гарантійний талон. Ми надаємо гарантію від сервісу до 3х років на всі послуги та комплектуючі.",
    },
]

export const prices = [
    {
        title: "Комп'ютери",
        services: [
            {
            service: "Перевстановлення ОС",
            price: "від 150 грн",
            garanty: "до 3 міс",
            terms: "Від  1 години",
        },
            {
            service: "Пришвидшення роботи",
            price: "від 200 грн",
            garanty: "до 3 міс",
            terms: "Від  1 години",
        },
            {
            service: "Програмна оптимізація",
            price: "від 150 грн",
            garanty: "до 2 міс",
            terms: "Від 1 години",
        },
            {
            service: "Апаратна чистка",
            price: "від 200 грн",
            garanty: "до 4 міс",
            terms: "Від 1 години",
        },
            {
            service: "Апгрейд комплектуючих",
            price: "від 400 грн",
            garanty: "до 5 міс",
            terms: "Від 3 роб. днів",
        },
            {
            service: "Захист від шахрайських програм",
            price: "від 250 грн",
            garanty: "до 3 міс",
            terms: "Від 1 години",
        },
            {
            service: "Захист від реклами",
            price: "від 250 грн",
            garanty: "до 5 міс",
            terms: "Від 1 години",
        },
            {
            service: "Заміна термопасти",
            price: "від 150 грн",
            garanty: "до 4 міс",
            terms: "Від 1 години",
        },
            {
            service: "Обслуговуванная водяного охолождення",
            price: "від 500 грн",
            garanty: "до 4 міс",
            terms: "Від 1 години",
        },
            {
            service: "Ремонт материнської плати",
            price: "від 500 грн",
            garanty: "до 5 міс",
            terms: "Від 2 годин",
        },
            {
            service: "Відновлення даних (заміна SSD)",
            price: "від 400 грн",
            garanty: "до 5 міс",
            terms: "Від 1 роб. дня",
        },
    ],
    },
    {
        title: "Телевізори",
        services: [
            {
            service: "Налаштування сигналу",
            price: "від 150 грн",
            garanty: "до 2 міс",
            terms: "Від 1 години",
        },
            {
            service: "Прошивка ТВ",
            price: "від 250 грн",
            garanty: "до 3 міс",
            terms: "Від 1 роб. Дня",
        },
            {
            service: "Заміна конденсаторів",
            price: "від 300 грн",
            garanty: "до 3 міс",
            terms: "Від 1 роб. Дня",
        },
            {
            service: "Ремонт та встановлення нових динаміків",
            price: "від 500 грн",
            garanty: "до 3 міс",
            terms: "Від 2 роб. Днів",
        },
            {
            service: "Заміна підсвітки   (залежить від діагоналі)",
            price: "від 500 грн",
            garanty: "до 3 міс",
            terms: "Від 2 роб. Днів",
        },
            {
            service: "ремонт/заміна Т-сon",
            price: "від 500 грн",
            garanty: "до 3 міс",
            terms: "Від 3 роб. Днів",
        },
            {
            service: "Відновлення звуку",
            price: "від 500 грн",
            garanty: "до 3 міс",
            terms: "Від 3 роб. Днів",
        },
            {
            service: "Ремонт мейн плати",
            price: "від 650 грн",
            garanty: "до 4 міс",
            terms: "Від 3 роб. Днів",
        },
            {
            service: "Ремонт блока живлення",
            price: "від 650 грн",
            garanty: "до 4 міс",
            terms: "Від 3 роб. Днів",
        },
            {
            service: "Доробка драйверу підсвітки",
            price: "від 700 грн",
            garanty: "до 4 міс",
            terms: "Від 1 роб. Дня",
        },
            {
            service: "Заміна процесора",
            price: "від 700 грн",
            garanty: "до 4 міс",
            terms: "Від 3 роб. Днів",
        },
            {
            service: "Відновлення працездатності матриці",
            price: "від 2000 грн",
            garanty: "до 5 міс",
            terms: "Від 5 роб.днів",
        },
            {
            service: "Заміна матриці",
            price: "від 2000 грн",
            garanty: "до 5 міс",
            terms: "Від 5 роб.днів",
        },
    ],
    },
    {
        title: "Ноутбуки",
        services: [
            {
            service: "Перевстановлення ОС",
            price: "від 150 грн",
            garanty: "до 12 міс",
            terms: "Від 1 години",
        },
            {service: "Встановлення і налаштування антивірусних програм",
            price: "від 250 грн",
            garanty: "до 12  міс",
            terms: "Від 1 години",
        },
            {
            service: "Пришвидшення роботи",
            price: "від 200 грн",
            garanty: "до 6 міс",
            terms: "Від 1 години",
        },
            {
            service: "Програмна оптимізація",
            price: "від 150 грн",
            garanty: "до 6 міс",
            terms: "Від 1 години",
        },
            {
            service: "Апаратна чистка",
            price: "від 200 грн",
            garanty: "до 6 міс",
            terms: "Від 1 години",
        },
            {
            service: "Заміна термопасти",
            price: "від 150 грн",
            garanty: "до 6 міс",
            terms: "Від 1 години",
        },
            {
            service: "Заміна рідкого металу",
            price: "від 500 грн",
            garanty: "до 6 міс",
            terms: "Від 1 години",
        },
            {
            service: "Заміна кулера",
            price: "від 500 грн",
            garanty: "до 6 міс",
            terms: "Від 1 роб. дня",
        },
            {
            service: "Заміна АКБ",
            price: "від 150 грн",
            garanty: "до 6 міс",
            terms: "Від 1 роб. дня",
        },
            {
            service: "Відновлення даних (заміна SSD)",
            price: "від 400 грн",
            garanty: "до 6 міс",
            terms: "Від 1 роб. Дня",
        },
            {
            service: "Ремонт материнської плати",
            price: "від 400 грн",
            garanty: "до 6 міс",
            terms: "Від 3 роб. Днів",
        },
            {
            service: "Заміна клавіатури",
            price: "від 500 грн",
            garanty: "до 6 міс",
            terms: "Від 1 роб. дня",
        },
            {
            service: "Відновлення та заміна елементів корпусу",
            price: "від  300 грн",
            garanty: "до 6 міс",
            terms: "Від 3 роб. Днів",
        },
            {
            service: "Ремонт роз’єму живлення",
            price: "від 500 грн",
            garanty: "до 5 міс",
            terms: "Від 1 роб. Дня",
        },
            {
            service: "Ремонт або заміна матриці",
            price: "від 1000 грн",
            garanty: "до 8 міс",
            terms: "Від 3 роб. Днів",
        },
    ],
    },
    {
        title: "MacBook",
        services: [
            {
            service: "Перевстановлення Mac OS",
            price: "від 600 грн",
            garanty: "до 12 міс",
            terms: "Від 1 години",
        },
            {
            service: "Встановлення Windows",
            price: "від 600 грн",
            garanty: "до 12 міс",
            terms: "Від 1 години",
        },
            {
            service: "Комплексна чистка та заміна термопасти",
            price: "від 1400 грн",
            garanty: "до 6 міс",
            terms: "Від 1 години",
        },
            {
            service: "Відновлення після потрапляння рідини",
            price: "від 1300 грн",
            garanty: "до 6 міс",
            terms: "Від 3 роб. днів ",
        },
            {
            service: "Заміна акумулятора",
            price: "від 1000 грн",
            garanty: "до 6 міс",
            terms: "Від 2 роб. днів",
        },
            {
            service: "Заміна елементів  корпуса",
            price: "від 1600 грн",
            garanty: "до 6 міс",
            terms: "Від 2 роб. Днів",
        },
            {
            service: "Відновлення даних (заміна SSD)",
            price: "від 1800 грн",
            garanty: "до 6 міс",
            terms: "Від 2 роб. Днів",
        },
            {
            service: "Заміна WI-FI модуля",
            price: "від 1500 грн",
            garanty: "до 6 міс",
            terms: "Від 2 роб. Днів",
        },
            {
            service: "Перенос даних",
            price: "від 1600 грн",
            garanty: "до 6 міс",
            terms: "Від 2 роб. Днів",
        },
            {
            service: "Заміна матриці",
            price: "від 3000 грн",
            garanty: "до 8 міс",
            terms: "Від 4 роб. Днів",
        },
            {
            service: "Ремонт роз’єму живлення",
            price: "від 1100 грн",
            garanty: "до 6 міс",
            terms: "Від 1 роб. Дня",
        },
    ],
    },
]