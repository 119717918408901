import phoneNumbers from '../../utils/phoneNumbers';
import './Footer.css'

const Footer = ({city}) => {
  const phoneNumber = phoneNumbers[city] || phoneNumbers.default; 

  return (
    <footer className='footer'>
      <div className="footer__wrapper">
        <h2 className="footer__logo arial-b">Tech<span className='brand-color'>Fix</span></h2>
        <a className='footer__phone mont-b' href={`tel:${phoneNumber}`}>{phoneNumber}</a>
      </div>
    </footer>
  )
}

export default Footer