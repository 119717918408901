import './Header.css'
import phoneNumbers from '../../utils/phoneNumbers'; // Импортируем номера телефонов

const Header = ({city}) => {
 
  const phoneNumber = phoneNumbers[city] || phoneNumbers.default; 

  const handleScrollClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    const yOffset = -90;
    const yPosition = section.getBoundingClientRect().top + window.pageYOffset + yOffset;

    
    window.scrollTo({
      top: yPosition,
      behavior: 'smooth'
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <header className="header">
        <div className="header__wrapper">
            <h2 onClick={scrollToTop} className='header__logo arial-b'>Tech <span className='brand-color'>Fix</span></h2>
            <nav className="header__nav">
            <p className="header__nav-item mont-sb" onClick={() => handleScrollClick('about')}>Про нас</p>
          <p className="header__nav-item mont-sb" onClick={() => handleScrollClick('repair')}>Ремонтуємо</p>
          <p className="header__nav-item mont-sb" onClick={() => handleScrollClick('prices')}>Ціни</p>
          <p className="header__nav-item mont-sb" onClick={() => handleScrollClick('stages')}>Етапи робіт</p>
          <p className="header__nav-item mont-sb" onClick={() => handleScrollClick('feedbacks')}>Відгуки</p>
            </nav>
            <a className='header__button int-sb' href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </div>
    </header>
  )
}

export default Header