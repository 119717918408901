import "./AboutUs.css";
import aboutUsImage from "../../assets/images/about-us-image.webp";

const AboutUs = () => {
  return (
    <section id="about" className="about__us">
      <div className="about__us-wrapper">
        <div className="about__us-elipse"></div>
        <div className="about__us-elipse-two"></div>
        <div className="about__us-texts">
          <h2 className="about__us-title mont-b">Про компанію</h2>
          <p className="about__us-description int-m">
            Наша компанія працює на ринку послуг вже понад 8 років. За цей час
            нам вдалося поєднати технологічну складову із індивідуальним
            підходом до кожного клієнта. Ми розуміємо, як важливо в сучасних
            умовах швидко і якісно налаштувати техніку або усунути будь-яке її
            пошкодження.
          </p>
          <p className="about__us-description int-m">
            Тому створили такий сервісний центр, який враховує всі можливі
            ризики і працює лише із якісними комплектуючими. За роки існування
            ми створили базу постійних клієнтів, які нам довіряють і радять
            своїм знайомим.
          </p>
          <p className="about__us-description int-m">
            Завдяки цьому ми змогли розвинути невеличку майстерню до
            повноцінного сервісного центру. Сьогодні нашими послугами
            користуються на лише фізичні особи, а і корпоративні організації по
            всьому місту.
          </p>
          <p className="about__us-description int-m">
            Ми зацікавлені не тільки в тому, щоб ваша техніка працювала як нова,
            а і в тому щоб наша держава розвивалась, тому частину прибутку
            завжди передаємо на благодійність.
          </p>
        </div>
        <img
          src={aboutUsImage}
          alt="картинка, на якій зображений фото опис нашої компанії"
          className="about__us-image"
        />
      </div>
    </section>
  );
};

export default AboutUs;
