import './Benefits.css'
import benefiteIconOne from '../../assets/icons/benefite-icon-one.png'
import benefiteIconTwo from '../../assets/icons/benefite-icon-two.png'
import benefiteIconThree from '../../assets/icons/benefite-icon-three.png'
import benefiteIconFour from '../../assets/icons/benefite-icon-four.png'
import Benefite from './Benefite/Benefite'

const benefitsArr = [
  {
    title: "Швидкий виїзд",
    description: "Ми цінуємо час наших клієнтів та готові виїхати до вас протягом години після отримання заявки.",
    src: benefiteIconFour,
  },
  {
    title: "Гарантія до 3 років",
    description: "Ми надаємо гарантію від сервісу до 3 років на будь-які наші послуги та комлектуючі.",
    src: benefiteIconOne,
  },
  {
    title: "8 років на ринку",
    description: "Всі наші майстри - кваліфіковані спеціалісти із досвідом роботи від 8 років.",
    src: benefiteIconTwo,
  },
  {
    title: "Без вихідних",
    description: "Працюємо 7 днів на тиждень, 365 на рік, щоб вчасно усувати будь-яку несправність.",
    src: benefiteIconThree,
  },
]

const Benefits = () => {
  return (
    <section className='benefits'>
      <div className="benefits__wrapper">
        <div className="benefite__elipse"></div>
        <div className="benefite__elipse-two"></div>
      {
        benefitsArr.map((benefite, idx) => {
          return (
            <Benefite key={idx} title={benefite.title} src={benefite.src} description={benefite.description}  />
          )
        })
      }
      </div>
    </section>
  )
}

export default Benefits