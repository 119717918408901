import feedbackKyivOne from '../assets/images/feedpack-kyiv-one.webp'
import feedbackKyivTwo from '../assets/images/feedpack-kyiv-two.webp'
import feedbackKyivThree from '../assets/images/feedpack-kyiv-three.webp'
import feedbackDneprOne from '../assets/images/feedpack-dnepr-one.webp'
import feedbackDneprTwo from '../assets/images/feedpack-dnepr-two.webp'
import feedbackDneprThree from '../assets/images/feedpack-dnepr-three.webp'

export const feedbacksArrKyiv = [
    {
        src: feedbackKyivOne,
        title: "Данило",
        description: "Нещодавно мені знадобилося замінити розбиту матрицю на телевізорі. Залишився вкрай задоволений! Мій телевізор прийняли швидко, провели діагностику і запропонували приємну ціну ремонту.",
    },
    {
        src: feedbackKyivTwo,
        title: "Олексій",
        description: "Майстри залишили приємне враження. Давав у ремонт телевізор Samsung 55 діагональ. Попросив зробити терміново. За три дні з доставкою комплектуючих встигли вже мені його видати. Якість ремонту на рівні. Ціна влаштувала.",
    },
    {
        src: feedbackKyivThree,
        title: "Максим",
        description: "Звернувся для ремонту ноутбука і залишився дуже задоволений. Персонал був привітним і професійним, швидко розібралися з моєю проблемою. Ремонт було виконано наступного дня, і ноутбук тепер працює як новий.",
    },
]

export const feedbacksArrDnipro = [
    {
        src: feedbackDneprOne,
        title: "Олег",
        description: "Звернувся з ігровим ноутом, дуже грівся і гудів, хлопці зробили за 2 години. Проблема виявилася банальна, потрібно було почистити і поміняти прокладки. Сподобалося те, що зробили швидко і не дорого. Хлопцям респект!!!",
    },
    {
        src: feedbackDneprTwo,
        title: "Степан",
        description: "Щиро дякую майстрам за відмінну роботу! Професійно вирішив усі проблеми з комп'ютером, тепер усе працює бездоганно. Дуже приємно було мати справу з таким досвідченим і ввічливим фахівцем. Рекомендую всім!",
    },
    {
        src: feedbackDneprThree,
        title: "Валерій",
        description: "Звернувся з телевізором так як пропало зображення, хлопці швидко провели діагностику і сказали в чому проблема, за часом зайняло 3 дні і телевізор вже у мене, сервіс 5/5, ціни прийнятні",
    },
]