import React from 'react'
import AboutUs from '../components/AboutUs/AboutUs'
import Benefits from '../components/Benefits/Benefits'
import Cover from '../components/Cover/Cover'
import Feedbacks from '../components/Feedbacks/Feedbacks'
import MainForm from '../components/MainForm/MainForm'
import Prices from '../components/Prices/Prices'
import Repair from '../components/Repair/Repair'
import Steps from '../components/Steps/Steps'
import { feedbacksArrKyiv } from '../utils/feedbacksArr'

const DefaultPage = ({city}) => {
  return (
    <div>
        <Cover city={city} />
        <Benefits />
        <AboutUs />
        <Repair />
        <Prices />
        <Steps />
        <MainForm />
        <Feedbacks feedbackArr={feedbacksArrKyiv}/>
    </div>
  )
}

export default DefaultPage