import "./Steps.css";
import React from "react";
import { stepsArr } from "../../utils/arrays";
import Step from "./Step/Step";
import stepsImage from "../../assets/images/stepsImage.webp";

const Steps = () => {
  return (
    <section id="stages" className="steps__section">
      <h2 className="steps__title mont-b">Етапи робіт з нами</h2>
      <div className="steps__section-wrapper">
        <div className="steps__elipse"></div>
        <div className="steps__elipse-two"></div>
        <div className="steps__elipse-three"></div>
        <div className="steps">
          {stepsArr.map((step, idx) => {
            return (
              <Step
                key={idx}
                stepTitle={step.title}
                stepDescription={step.description}
                stepNumber={step.number}
              />
            );
          })}
        </div>
        <img
          src={stepsImage}
          alt="на картинці зображений процес нашої роботи"
          className="steps__image"
        />
      </div>
    </section>
  );
};

export default Steps;
