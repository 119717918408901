import { useState } from 'react';
import './Prices.css'
import { prices } from '../../utils/arrays';
import AccordionItem from './AccordionItem/AccordionItem';


const Prices = () => {
    const [openId, setId] = useState(null);

  return (
    <section id="prices" className='prices'>
        <h2 className="prices__title mont-b">Всі послуги та ціни</h2>
         <ul className="accordion">
      {prices.map((item, id) => {
       return <AccordionItem onClick={() => id === openId ? setId(null) : setId(id) } isOpen={id===openId} item={item} key={id} />
      })}
    </ul>
    </section>
  )
}
export default Prices