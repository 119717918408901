import { Route, Routes, BrowserRouter as Router, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import "./App.css";
import AppRouter from "./components/AppRouter/AppRouter";
import DefaultPage from "./Pages/DefaultPage";
import DniproCityPage from "./Pages/DniproCityPage";
import KyivCityPage from "./Pages/KyivCityPage";
import CityModal from "./components/CityModal/CityModal";

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

function App() {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); 
  const [city, setCity] = useState(null)

  useEffect(() => {
    const savedCity = localStorage.getItem("city");

    if (!savedCity) {
      if (location.pathname === "/dnipro") {
        setCity("dnipro");
        localStorage.setItem("city", "dnipro");
        setShowModal(false); 
      } else if (location.pathname === "/kyiv") {
        setCity("kyiv");
        localStorage.setItem("city", "kyiv");
        setShowModal(false);
      } else {
        setShowModal(true); 
      }
    } else {
      setCity(savedCity);
      if (location.pathname !== `/${savedCity}`) {
        navigate(`/${savedCity}`);
      }
      
    }
  }, [location.pathname, navigate]);

  const handleCitySelect = (selectedCity) => {
    setCity(selectedCity);
    localStorage.setItem("city", selectedCity);
    setShowModal(false);
    navigate(`/${selectedCity}`); 
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
      <div className="App">
        <Routes>
          <Route path="/" element={<AppRouter city={city} />}>
            <Route index element={<DefaultPage city={city} />} />
            <Route path="dnipro" element={<DniproCityPage city={city} />} />
            <Route path="kyiv" element={<KyivCityPage city={city} />} />
          </Route>
        </Routes>

        {showModal && <CityModal onSelect={handleCitySelect} onClose={handleCloseModal} />}
      </div>
  );
}

export default AppWrapper;
