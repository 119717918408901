import './Benefite.css'

const Benefite = ({src, title, description}) => {
  return (
    <div className='benefite'>
        <div className="benefite__icon-wrapper">
            <img src={src} alt="іконка нашої переваги над конкурентами" className="beenefite__icon" />
        </div>
        <h3 className="benefite__title mont-b">{title}</h3>
        <p className="benefite__description int-m">{description}</p>
    </div>
  )
}

export default Benefite