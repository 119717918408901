import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isActive: false,
  isFormSended: false,
};

const ModalSlice = createSlice({
  name: "Modal",
  initialState,
  reducers: {
   setIsActive: (state, action) => {
    state.isActive = true
   },
   setIsClose: (state, action) => {
    state.isActive = false
   },
   setIsFormSended: (state) => {
    state.isFormSended = true;
  },
  setClearIsFormSended: (state) => {
    state.isFormSended = false;
  },
  },
});

export const {
  setIsActive,
  setIsClose,
  setIsFormSended,
  setClearIsFormSended,
} = ModalSlice.actions;
export default ModalSlice.reducer;
