import "./CallButton.css";
import phoneIcon from "../../assets/icons/phone-icon.png";
import { setClearIsFormSended, setIsActive } from "../../redux/ModalSlice/ModalSlice";
import { useDispatch } from "react-redux";

const CallButton = () => {
  const dispatch = useDispatch();
  const handleModalOpen = () => {
    dispatch(setIsActive());
    dispatch(setClearIsFormSended())
  };
  return (
    <div onClick={handleModalOpen} className="btn-call">
      <div className="btn-call__ico">
        <img
          src={phoneIcon}
          alt="іконка при натискнні на яку відкриється форма зворотнього зв'язку"
          className="fas fa-phone-alt"
        />
      </div>
    </div>
  );
};

export default CallButton;
