import './Step.css'

const Step = ({stepTitle, stepDescription, stepNumber}) => {
  return (
    <div className="step__benefite">
    <div className={`step__benefite-number mont-b brand-color`}>{stepNumber}</div>
    <div className="cstep_benefite-texts">
    <div className="step__benefite-header">
    <div className={`step__benefite-number-mobile mont-b brand-color`}>{stepNumber}</div>

        <h2 className={`step__benefite-title mont-b`}>{stepTitle}</h2>
    </div>
    <p className="step__benefite-description int-m">{stepDescription}</p>
    </div>
</div>
  )
}

export default Step
