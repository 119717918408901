
import { useState } from 'react';
import './Repair.css'
import pcImage from '../../assets/images/pcImage.webp'
import TvImage from '../../assets/images/tvImage.webp'
import LaptopImage from '../../assets/images/laptopImage.webp'

const repairArr = [
    {
        type: "Комп'ютер"
    },
    {
        type: "Телевізор"
    },
    {
        type: "Ноутбук"
    },
]

const repairPriceArr = [
    [
    {
        title: "Повільно працює, зависає",
        price: 150,
    },
    {
        title: "Зараження вірусами",
        price: 200,
    },
    {
        title: "Не вмикається (або синій екран)",
        price: 250,
    },
    {
        title: "Не встановлюється програма або гра",
        price: 250,
    },
    {
        title: "Виникають сповіщення про помилки на екрані",
        price: 250,
    },
    {
        title: "Самовільне вимкнення",
        price: 300,
    },
],
    [
    {
        title: "Немає зображення",
        price: 300,
    },
    {
        title: "Немає звуку",
        price: 300,
    },
    {
        title: "Смуги на екрані",
        price: 400,
    },
    {
        title: "Немає зображення",
        price: 600,
    },
    {
        title: "Самовільне вимкнення під час роботи",
        price: 600,
    },
    {
        title: "Пошкоджена матриця",
        price: 1200,
    },
],
    [
    {
        title: "Повільно працює, зависає",
        price: 150,
    },
    {
        title: "Зараження вірусами, усунути надокучливу рекламу",
        price: 250,
    },
    {
        title: "Пристрій перегрівається",
        price: 250,
    },
    {
        title: "Не бачить сигнал WI-FI",
        price: 300,
    },
    {
        title: "Не вмикається",
        price: 350,
    },
    {
        title: "Оптичні артефакти на екрані",
        price: 400,
    },
],
]

const srcArr = [
    pcImage,
    TvImage,
    LaptopImage,

]

const Repair = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const currentImage = srcArr[activeIndex]

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <section id="repair" className='repair'>
        <h2 className="repair__title mont-b">Полагодимо техніку</h2>
        <div className="repair__select">
        {repairArr.map((item, index) => (
              <div
                key={index}
                className={`repair__select-item mont-b ${activeIndex === index ? 'active' : ''}`}
                onClick={() => handleItemClick(index)}
              >
                {item.type}
              </div>
            ))}
        </div>
        <div className="repair__prices-photo">
            <div className="repair__prices-wrapper">
                <div className="repair__prices-texts">
                    {
                        repairPriceArr[activeIndex].map((e, idx) => {
                            return (
                                <div 
                                key={idx} className="repair__prices-text">
                                    <h3 className="repair__prices-title mont-b">{e.title}</h3>
                                    <p className="repair__prices-price mont-b">Від <span className='brand-color'>{e.price}</span> ₴</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="repair__photo-wrapper">
                <div className="repair__photo-elipse"></div>
                <img src={currentImage} alt="картинка вибраного пристрою" className="current__image" />
            </div>
        </div>
    </section>
  )
}

export default Repair