import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ModalForm from "../ModalForm/ModalForm";
import CallButton from "../CallButton/CallButton";

const AppRouter = ({city}) => {

  return (
    <div className="global__container">
      <Header city={city} />
      <main className="main">
        <Outlet /> 
      </main>
      <Footer city={city}/>
      <ModalForm />
      <CallButton />
    </div>
  );
};

export default AppRouter;

