import'./Feedbacks.css'

const Feedbacks = ({feedbackArr}) => {
  return (
    <section id='feedbacks' className='feedback__section'>
        <h2 className="feedback__section-title mont-b">Відгуки наших клієнтів</h2>
        <div className="feedback__section-wrapper">
            {
                feedbackArr.map((item, idx) => {
                    return (
                    <div key={idx} className="feedback">
                        <img src={item.src} alt="фото людини, яка залишила відгук" className="feedback__image" />
                        <h3 className="feedback__title mont-b">{item.title}</h3>
                        <p className="feedback__description int-m">{item.description}</p>
                    </div>
                    )
                })
            }
        </div>
    </section>
  )
}

export default Feedbacks